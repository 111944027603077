/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { CompactTable } from '@table-library/react-table-library/compact'
import Button from 'components/common/Button'
import Modal from 'containers/modal/Modal'
import Title from 'components/common/Title'
import { useDispatch, useSelector } from 'react-redux'
import { openModal } from 'redux/features/general/generalSlice'
import { FaArrowLeft, FaCheck } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useRequest } from 'hooks/useRequest'
import moment from 'moment'
import SelectDr from 'components/common/SelectDr'
import Loading from 'components/common/Loading'
import images from 'assets/images/images'
import { RxDownload } from 'react-icons/rx'
import { FiDownload, FiEdit } from 'react-icons/fi'
import { IoClose } from 'react-icons/io5'
import {Tooltip} from 'react-tooltip';

const OutgoingGiftDetail = () => {
	const filesStyle = {
		display: 'flex',
		alignItems: 'center',
		gap: '4px',
		textDecoration: 'underline',
		cursor: 'pointer',
	}
	const { t, i18n } = useTranslation()
	const { id } = useParams()
	const [request, loading] = useRequest()
	const dispatch = useDispatch()
	const { modals } = useSelector((state) => state.general)
	const navigate = useNavigate()

	const [details, setDetails] = useState()
	const [targetCompanyId, setTargetCompanyId] = useState()

	const [isGeneralManagerApprovalRequired, setIsGeneralManagerApprovalRequired] = useState()
	// controller
	const [isWarningApproved, setIsWarningApproved] = useState(false)

	let substitutePage = window.location.pathname.split('/')[1] == 'substitute-panel'

	const { modalToggle, modalStatus, modalId } = modals

	const [giftRejectReasonId, setGiftRejectReasonId] = useState()

	//options
	const [rejectReasonOPTIONS, setRejectReasonOPTIONS] = useState()
	const [targetCompanySelectList, setTargetCompanySelectList] = useState()

	useEffect(() => {
		if (!id) return

		request(`/gift-transaction/outgoing/${id}`, {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				setDetails(res.data.result)
			}
		})
	}, [id])

	useEffect(() => {
		request(`/gift/reject-reason/select-list`, {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				setRejectReasonOPTIONS(res.data.result.items)
			}
		})

		request('/company/select-list?Export=true&IncludeCompanySpecific=true', {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				setTargetCompanySelectList(res.data.result.items)
			}
		})
	}, [])

	const approveStatus = (status) => {
		switch (status) {
			case 0:
				return 'Onay Bekliyor'
			case 1:
				return 'Onaylandı'
			case 2:
				return 'Reddedildi'
			default:
				return 'Onaylandı'
		}
	}

	const downloadFile = (file) => {
		request(`/file/${file}`, {
			method: 'GET',
		}).then((res) => {
			if (res.data.ok) {
				const byteCharacters = atob(res.data.result)
				const byteNumbers = new Array(byteCharacters.length)
				for (let i = 0; i < byteCharacters.length; i++) {
					byteNumbers[i] = byteCharacters.charCodeAt(i)
				}
				const byteArray = new Uint8Array(byteNumbers)
				const blob = new Blob([byteArray], { type: 'application/octet-stream' })
				const url = URL.createObjectURL(blob)
				const link = document.createElement('a')
				link.href = url
				link.download = file
				link.click()
				URL.revokeObjectURL(url)
			}
		})
	}

	const editableRowInTable = (approveStatus) => {
		request(`/gift-transaction/incoming/change-company`, {
			method: 'POST',
			data: {
				id: id,
				targetCompanyId: targetCompanyId?.__isNew__ ? null : targetCompanyId?.value,
				customTargetCompanyName: targetCompanyId?.__isNew__ ? targetCompanyId?.value : null,
				isApproved: approveStatus,
			},
		}).then((res) => {
			if (res.data.ok) {
				request(`/gift-transaction/outgoing/${id}`, {
					method: 'GET',
				}).then((res) => {
					if (res.data.ok) {
						setDetails(res.data.result)
						toast.success(t('updatesuccessful'))
					}
				})
			}
		})
	}

	const approvalOfficer = {
		0: t('system'),
		1: t('manager2'),
		2: t('complianceManager'),
		3: t('generalManager'),
	}

	const nodes = [
		{ name: t('CreatingUser'), desc: `: ${details?.userFullName}` },
		{ name: t('GiftDate'), desc: `: ${details?.createdDate && moment(details.createdDate).format('YYYY-MM-DD')}` },
		{ name: t('companyName'), desc: `: ${details?.companyName}` },
		// { name: t('submittedInstitutionName'), desc: `: ${details?.targetCompanyName}` },
		{
			name: t('submittedInstitutionName'),
			desc: (
				<div className='editableRowInTable'>
					: {details?.targetCompanyName}
					{(details?.targetCompanyApproverManagerFullName || details?.targetCompanyApproverComplianceManagerFullName) && (
						<>
							{details?.targetCompanyApproverManagerFullName && (
								// <img
								// 	title={
								// 		t('administratorOfTheTransaction') +
								// 		' : ' +
								// 		details?.targetCompanyApproverManagerFullName +
								// 		' : ' +
								// 		moment(details?.targetCompanyManagerApprovalDate).format('YYYY-MM-DD')
								// 	}
								// 	src={images.warningRounded}
								// 	alt=''
								// />
								<>
								<img
								  data-tooltip-id="tooltip-info"
								  data-tooltip-content={
									`${t('administratorOfTheTransaction') +
										' : ' +
										details?.targetCompanyApproverManagerFullName +
										' : ' +
										moment(details?.targetCompanyManagerApprovalDate).format('YYYY-MM-DD')}`
								  }
								  src={images.warningRounded}
								  alt=''
								/>
								<Tooltip id="tooltip-info" style={{ maxWidth: '100%' }} />
							  </>
							)}
							{details?.targetCompanyApproverComplianceManagerFullName && (
								// <img
								// 	title={
								// 		t('complianceOfficerPerformingTheProcess') +
								// 		' : ' +
								// 		details?.targetCompanyApproverComplianceManagerFullName +
								// 		' : ' +
								// 		moment(details?.targetCompanyComplianceManagerApprovalDate).format('YYYY-MM-DD')
								// 	}
								// 	src={images.warningRounded}
								// 	alt=''
								// />
								<>
								<img
								  data-tooltip-id="tooltip-info"
								  data-tooltip-content={
									`${t('complianceOfficerPerformingTheProcess') +
										' : ' +
										details?.targetCompanyApproverComplianceManagerFullName +
										' : ' +
										moment(details?.targetCompanyComplianceManagerApprovalDate).format('YYYY-MM-DD')}`
								  }
								  src={images.warningRounded}
								  alt=''
								/>
								<Tooltip id="tooltip-info" style={{ maxWidth: '100%' }} />
							  </>
							)}
						</>
					)}
					{details?.targetCompanyIsWaitingForApproval && 
					// <img title={t('notApprovedInstitution')} src={images.warningRounded} alt='' />
					<>
								<img
								  data-tooltip-id="tooltip-info"
								  data-tooltip-content={
									`${t('notApprovedInstitution')}`
								  }
								  src={images.warningRounded}
								  alt=''
								/>
								<Tooltip id="tooltip-info" style={{ maxWidth: '100%' }} />
							  </>
					}
					{/* {details?.canModifyTargetCompany && !editableRowMode && <div className='iconEdit' onClick={() => setEditableRowMode(true)}><FiEdit /></div>} */}
					{details?.canModifyTargetCompany && (
						<div className='editableRowInTable-selectContainer'>
							<div>
								<label style={{ fontSize: '12px' }} htmlFor=''>
									{t('changeOfInstitution')}
								</label>
								<SelectDr
									type={0}
									onChange={(value) => setTargetCompanyId(value ? value : '')}
									options={targetCompanySelectList?.map((prev) => ({ value: prev.name, label: prev.value }))}
									placeholder={t('selectOrCreate')}
									label={''}
									creatableSelect={true}
									fixedMenuPosition={true}
								/>
							</div>
							<div>
								<label style={{ fontSize: '12px' }} htmlFor=''>
									{t('institutionApproval')}
								</label>
								<div style={{ display: 'flex', alignItems: 'center', gap: 3, marginTop: '4px' }}>
									
									<>
									<div
										data-tooltip-id="tooltip-info"
										data-tooltip-content={
										  `${t('confirm')}`
										}
										style={{ backgroundColor: '#4ca54c' }}
										className='editableRowInTable-selectContainer-btn'
										onClick={() => editableRowInTable(true)}
									>
										{' '}
										<FaCheck />
									</div>
								   <Tooltip id="tooltip-info" style={{ maxWidth: '100%' }} />
							        </>
									<>
									<div
										data-tooltip-id="tooltip-info"
										data-tooltip-content={
										  `${t('reject')}`
										}
										style={{ backgroundColor: '#c1312f' }}
										className='editableRowInTable-selectContainer-btn'
										onClick={() => editableRowInTable(false)}
									>
										<IoClose size={20} />
									</div>
									<Tooltip id="tooltip-info" style={{ maxWidth: '100%' }} />
									</>
									
								</div>
							</div>
						</div>
					)}
					{/* <div className='editableRowInTable-selectContainer'>
					<SelectDr
						type={0}
						onChange={(value) => setTargetCompanyId(value ? value : '')}
						options={targetCompanySelectList?.map((prev) => ({ value: prev.name, label: prev.value }))}
						placeholder={t('selectOrCreate')}
						label={""}
						creatableSelect={true} 
						fixedMenuPosition = {true}
				/>
				<div style={{backgroundColor: '#4ca54c'}} className='editableRowInTable-selectContainer-btn' onClick={() => editableRowInTable(true)}> <FaCheck/></div>
				<div style={{backgroundColor: '#c1312f'}} className='editableRowInTable-selectContainer-btn' onClick={() => editableRowInTable(false)}><IoClose size={20}/></div>
				</div> */}
				</div>
			),
		},
		// { name: t('SenderoftheGift'), desc: `: ${details?.senderName}` },
		{ name: t('GiftRecipient'), desc: `: ${details?.receiverName}` },
		{
			name: t('Amount'),
			desc: `: ${
				details?.amount && details?.currencyCode
					? new Intl.NumberFormat(i18n.language, { style: 'currency', currency: details.currencyCode }).format(details.amount)
					: details?.amount + ' ' + details?.currencyCode
			}`,
		},
		// { name: t('GiftDescription'), desc: `: ${details?.description}` },
		{ name: t('explanationOfReasonForReceivingGift'), desc: `: ${details?.giftPurposeDescription}` },
		{ name: t('giftDetail'), desc: `: ${details?.description}` },
		{
			name: t('GiftType'),
			desc: (
				<div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
					<span>{`: ${details?.giftTypeName}`}</span>
					{details?.giftTypeOutgoingApprovalRequired && 
					// <img title={t('selectionRequiringApproval')} src={images.warningRounded} alt='' />
					<>
								<img
								  data-tooltip-id="tooltip-info"
								  data-tooltip-content={
									`${t('selectionRequiringApproval')} : ${details?.targetCompanyApproverManagerFullName} : ${moment(details?.targetCompanyManagerApprovalDate).format('YYYY-MM-DD')}`
								  }
								  src={images.warningRounded}
								  alt=''
								/>
								<Tooltip id="tooltip-info" style={{ maxWidth: '100%' }} />
							  </>
					}
				</div>
			),
		},
		{
			name: t('PurposeoftheGift'),
			desc: (
				<div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
					<span>{`: ${details?.giftPurposeName}`}</span>
					{details?.giftPurposeOutgoingApprovalRequired && 
					// <img title={t('selectionRequiringApproval')} src={images.warningRounded} alt='' />
					<>
								<img
								  data-tooltip-id="tooltip-info"
								  data-tooltip-content={
									`${t('selectionRequiringApproval')} : ${details?.targetCompanyApproverManagerFullName} : ${moment(details?.targetCompanyManagerApprovalDate).format('YYYY-MM-DD')}`
								  }
								  src={images.warningRounded}
								  alt=''
								/>
								<Tooltip id="tooltip-info" style={{ maxWidth: '100%' }} />
							  </>
					}
				</div>
			),
		},
		{
			name: t('partyToWhichTheGiftWasSent'),
			desc: (
				<div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
					<span>{`: ${details?.giftRelatedPartyTypeName}`}</span>
					{details?.giftRelatedPartyTypeOutgoingApprovalRequired && (
						// <img title={t('selectionRequiringApproval')} src={images.warningRounded} alt='' />
						<>
								<img
								  data-tooltip-id="tooltip-info"
								  data-tooltip-content={
									`${t('selectionRequiringApproval')} : ${details?.targetCompanyApproverManagerFullName} : ${moment(details?.targetCompanyManagerApprovalDate).format('YYYY-MM-DD')}`
								  }
								  src={images.warningRounded}
								  alt=''
								/>
								<Tooltip id="tooltip-info" style={{ maxWidth: '100%' }} />
							  </>
					)}
				</div>
			),
		},
		{ name: t('ReasonforRejection'), desc: `: ${details?.giftRejectReasonName ? details?.giftRejectReasonName : ''}` },
		{ name: t('publicservant'), desc: `: ${details?.toPublicOfficial ? t('yes') : t('no')}` },
		{ name: t('ApprovalStatus'), desc: `: ${approveStatus(details?.approvalStatus)}` },

		{ name: (
			<div style={{display:'flex', alignItems: "center", gap: '4px'}}>
				<span>{t('approvalOfficer')}</span>
				<>
				<img
					data-tooltip-id="tooltip-info"
					data-tooltip-content={
					`${t('containsUserInformationResponsibleForTheApprovalProcess')}`
					}
					src={images.warningRounded}
					alt=''
				/>
				<Tooltip id="tooltip-info" style={{ maxWidth: '100%' }} />
				</>
	
			</div>
		), desc: `: ${details?.approvalResponsible ? approvalOfficer[details?.approvalResponsible] : ''}` },

		{ name: t('managerActionDate'), desc: `: ${details?.managerApprovalDate ? moment(details.managerApprovalDate).format('YYYY-MM-DD') : ''}` },
		{ name: t('managerOfTheTransaction'), desc: `: ${details?.approverManagerFullName ? details?.approverManagerFullName : ''}` },
		{
			name: t('complianceOfficerTransactionDate'),
			desc: `: ${details?.complianceManagerApprovalDate ? moment(details.complianceManagerApprovalDate).format('YYYY-MM-DD') : ''}`,
		},
		{
			name: t('complianceOfficerPerformingTheProcess'),
			desc: `: ${details?.approverComplianceManagerFullName ? details?.approverComplianceManagerFullName : ''}`,
		},
		{
			name: t('generalManagerTransactionDate'),
			desc: `: ${details?.generalManagerApprovalDate ? moment(details.generalManagerApprovalDate).format('YYYY-MM-DD') : ''}`,
		},
		{
			name: t('generalManagerPerformingOperations'),
			desc: `: ${details?.approverGeneralManagerFullName ? details?.approverGeneralManagerFullName : ''}`,
		},
		{ name: t('lastTransactionDate'), desc: `: ${details?.lastApprovalDate ? moment(details.lastApprovalDate).format('YYYY-MM-DD') : ''}` },

		// { name: t('managerApprovalDate'), desc: `: ${details?.managerApprovalDate ? moment(details.managerApprovalDate).format('YYYY-MM-DD') : ""}` },
		// { name: t('saltManager'), desc: `: ${details?.approverManagerFullName ? details?.approverManagerFullName : ""}` },
		// { name: t('complianceManagerApprovalDate'), desc: `: ${details?.complianceManagerApprovalDate ? moment(details.complianceManagerApprovalDate).format('YYYY-MM-DD') : ""}` },
		// { name: t('complianceOfficer'), desc: `: ${details?.approverComplianceManagerFullName ? details?.approverComplianceManagerFullName : ""}` },
		// { name: t('generalManagerApprovalDate'), desc: `: ${details?.generalManagerApprovalDate ? moment(details.generalManagerApprovalDate).format('YYYY-MM-DD') : ""}` },
		// { name: t('approverGeneralManagerFullName'), desc: `: ${details?.approverGeneralManagerFullName ? details?.approverGeneralManagerFullName : ""}` },
		// { name: t('lastApprovalDate'), desc: `: ${details?.lastApprovalDate ? moment(details.lastApprovalDate).format('YYYY-MM-DD') : ""}` },

		{
			name: t('files'),
			desc: (
				<div style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
					{details?.files?.map((file, i) => (
						<div onClick={() => downloadFile(file)} style={filesStyle} key={i}>
							<RxDownload />
							{file}
						</div>
					))}
				</div>
			),
		},
	]
	const data = { nodes }

	// const theme = useTheme([
	// 	getTheme(),
	// 	{
	// 	  Table: `
	// 		--data-table-library_grid-template-columns: repeat(2, minmax(150px, 1fr)) ;
	// 	  `,
	// 	},
	//   ]);

	const giftReject = () => {
		request(`/gift-transaction/outgoing/approve`, {
			method: 'POST',
			data: {
				id: id,
				isApproved: false,
				giftRejectReasonId: giftRejectReasonId?.__isNew__ ? null : giftRejectReasonId?.value,
				customGiftRejectReason: giftRejectReasonId?.__isNew__ ? giftRejectReasonId?.value : null,
			},
		}).then((res) => {
			if (res.data.ok) {
				request(`/gift-transaction/outgoing/${id}`, {
					method: 'GET',
				}).then((res) => {
					if (res.data.ok) {
						setDetails(res.data.result)
						dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))
						toast.success('İşlem Başarılı')
					}
				})
			}
		})
	}

	const giftApprove = () => {
		request(`/gift-transaction/outgoing/approve`, {
			method: 'POST',
			data: {
				id: id,
				isApproved: true,
				isGeneralManagerApprovalRequired: isGeneralManagerApprovalRequired,
				isWarningApproved: isWarningApproved,
			},
		}).then((res) => {
			if (res.data.result === false) {
				// toast.error(res.data.metadata.message, {autoClose: 10000})
				dispatch(openModal({ open: true, type: { modalId: res.data.metadata.message, modalStatus: 'warning' } }))
				setIsWarningApproved(true)
			} else if (res.data.ok) {
				request(`/gift-transaction/outgoing/${id}`, {
					method: 'GET',
				}).then((res) => {
					if (res.data.ok) {
						//toast.success('Ekleme işlemi başarılı')
						//navigate('/incoming-gift')
						setDetails(res.data.result)
						dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))
						toast.success('İşlem Başarılı')
					}
				})
			}
		})
	}

	const giftWithdrawConsent = () => {
		request(`/gift-transaction/revert-approval/${id}`, {
			method: 'POST',
			// data: {
			// 	id: id,
			// 	isApproved: true,
			// },
		}).then((res) => {
			if (res.data.ok) {
				request(`/gift-transaction/outgoing/${id}`, {
					method: 'GET',
				}).then((res) => {
					if (res.data.ok) {
						//toast.success('Ekleme işlemi başarılı')
						//navigate('/incoming-gift')
						setDetails(res.data.result)
						dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))
						toast.success('İşlem Başarılı')
					}
				})
			}
		})
	}

	function loadScripts(urls) {
		return new Promise((resolve, reject) => {
			const promises = urls.map((url) => {
				return new Promise((resolve, reject) => {
					const script = document.createElement('script')
					script.src = url
					script.async = true
					script.onload = resolve
					script.onerror = reject
					document.head.appendChild(script)
				})
			})

			Promise.all(promises)
				.then(() => resolve())
				.catch((error) => reject(error))
		})
	}

	let htmlToCanvas = (element, fileName, options) => {
		var newContent = document.createElement('div')
		newContent.textContent = t('outgoingGift') // Tercih ettiğiniz metni buraya ekleyin
		newContent.className = 'detailPDFTitle'
		element.appendChild(newContent)

		loadScripts([
			'https://cdnjs.cloudflare.com/ajax/libs/html2canvas/1.4.1/html2canvas.min.js',
			'https://cdnjs.cloudflare.com/ajax/libs/jspdf/2.5.1/jspdf.umd.min.js',
		]).then(() => {
			const defaultOptions = {
				windowWidth: 1600,
			}
			let margin = options?.margin ?? 0
			html2canvas(element, { ...defaultOptions, ...options }).then((canvas) => {
				const imgData = canvas.toDataURL('image/png')
				const pdf = new jspdf.jsPDF('l', 'mm', [(canvas.height / canvas.width) * 297, 297], true)
				const imgProps = pdf.getImageProperties(imgData)
				const pdfWidth = pdf.internal.pageSize.getWidth() - 2 * margin
				const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width
				pdf.internal.pageSize.setHeight(pdfHeight + 2 * margin)
				pdf.addImage(imgData, 'PNG', margin, margin, pdfWidth, pdfHeight)
				pdf.save(`${fileName}.pdf`)

				element.removeChild(newContent)
			})
		})
	}

	return (
		<div className='detail'>
			<div className='addItemsPage-title detailPage'>
				<div onClick={() => navigate('/outgoing-gift')} className='addItemsPage-title-icon'>
					<FaArrowLeft size={24} />
				</div>
				<div className='addItemsPage-title-text'>
					<Title name={t('details')} color='#1392e5' fontweight='bold' size='24px' />
				</div>
				<div
					onClick={() => htmlToCanvas(document.getElementById('detail-container-table-outgoing'), 'gidenhediyedetay')}
					className='addItemsPage-title-pdf'
				>
					PDF
					<FiDownload />
				</div>
			</div>
			{loading ? (
				<div className='panel-table-loading'>
					<Loading width='45px' height='45px' borderWidth='5px' />
				</div>
			) : (
				<div className='detail-container'>
					<div style={{ display: 'flex', flexDirection: 'column-reverse' }} id='detail-container-table-outgoing' className='detail-container-table'>
						<CompactTable
							columns={[
								{ key: 'name', header: 'Name', renderCell: (item) => <div className='cabin-bold'>{item.name}</div> },
								{ key: 'desc', header: 'Desc', renderCell: (item) => item.desc },
							]}
							data={data}
						/>
					</div>
					<div className='detail-container-buttonGroup'>
						{details?.canRevertApproval && (
							<Button
								onclick={() => dispatch(openModal({ open: true, type: { modalId: 1, modalStatus: 'withdrawConsent' } }))}
								name={t('withdrawConsent')}
								color='#fff'
								backgroundColor='blue'
								height='48px'
							/>
						)}
						{details?.canApprove && (
							<Button
								onclick={() => dispatch(openModal({ open: true, type: { modalId: 1, modalStatus: 'reject' } }))}
								name={t('reject')}
								color='#fff'
								backgroundColor='#c1312f'
								height='48px'
							/>
						)}
						{details?.canApprove && (
							<Button
								onclick={() => dispatch(openModal({ open: true, type: { modalId: 1, modalStatus: 'approve' } }))}
								name={t('confirm')}
								color='#fff'
								backgroundColor='#08a52b'
								height='48px'
							/>
						)}
					</div>
				</div>
			)}

			{modalToggle && modalStatus == 'reject' && modalId == 1 && (
				<Modal>
					<div className='modal-type1 modal-type1-changed'>
						<Title name={t('reasonforRefusal')} color='rgb(36, 36, 36)' fontweight='bold' size='24px' />
						{/* <textarea className='cabin-bold' name='' id='' cols='30' rows='10' placeholder={t('writebrieflyhere')} /> */}
						<div className='selectDr-modalReject'>
							<SelectDr
								onChange={(value) => setGiftRejectReasonId(value ? value : '')}
								type={0}
								options={rejectReasonOPTIONS?.map((prev) => ({ value: prev.name, label: prev.value, description: prev.description }))}
								placeholder={t('selectOrCreate')}
								label={t('accordingtoReturnSelection')}
								creatableSelect={true}
							/>
						</div>
						<div className='modal-type1-buttonGroup'>
							<Button
								onclick={() => dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))}
								name={t('no')}
								color='#fff'
								backgroundColor='#c1312f'
								height='48px'
							/>
							<Button onclick={giftReject} name={t('yes')} color='#fff' backgroundColor='#1392e5' height='48px' />
						</div>
					</div>
				</Modal>
			)}

			{modalToggle && modalStatus == 'approve' && modalId == 1 && (
				<Modal>
					<div className='modal-type1 modal-type1-changed'>
						<Title name={t('areYouSureYouWantToConfirm')} color='rgb(36, 36, 36)' fontweight='' size='20px' />
						{/* <textarea className='cabin-bold' name='' id='' cols='30' rows='10' placeholder='Kısaca buraya yazın' /> */}
						{details?.canSendToGeneralManagerApproval && (
							<div style={{ marginTop: '-12px', marginBottom: '-8px' }} className='modal-type1-checkArea'>
								<input onChange={(e) => setIsGeneralManagerApprovalRequired(e.target.checked)} type='checkbox' name='' id='' />
								<div>{t('isGeneralManagerApprovalRequired')}</div>
							</div>
						)}
						<div className='modal-type1-buttonGroup'>
							<Button
								onclick={() => dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))}
								name={t('no')}
								color='#fff'
								backgroundColor='#c1312f'
								height='48px'
							/>
							<Button onclick={giftApprove} name={t('yes')} color='#fff' backgroundColor='#1392e5' height='48px' />
						</div>
					</div>
				</Modal>
			)}

			{modalToggle && modalStatus == 'withdrawConsent' && modalId == 1 && (
				<Modal>
					<div className='modal-type1 modal-type1-changed'>
						<Title name={t('areYouSureYouWantToReverseYourConfirmation')} color='rgb(36, 36, 36)' fontweight='' size='20px' />
						{/* <textarea className='cabin-bold' name='' id='' cols='30' rows='10' placeholder='Kısaca buraya yazın' /> */}
						<div className='modal-type1-buttonGroup'>
							<Button
								onclick={() => dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))}
								name={t('no')}
								color='#fff'
								backgroundColor='#c1312f'
								height='48px'
							/>
							<Button onclick={giftWithdrawConsent} name={t('yes')} color='#fff' backgroundColor='#1392e5' height='48px' />
						</div>
					</div>
				</Modal>
			)}
			{isWarningApproved && modalToggle && modalStatus == 'warning' && (
				<Modal customModal={'sm-modal'}>
					<div className='modal-type1 modal-type1-changed' style={{ gap: '5px' }}>
						<div style={{ marginBottom: '10px' }}>
							{modalId?.split('\r\n')?.map((modalText, i) => (
								<Title name={modalText} color='#242424' fontweight='bold' size='16px' />
							))}
						</div>{' '}
						<div className='modal-type1-buttonGroup'>
							<Button
								onclick={() => dispatch(openModal({ open: false, type: { modalId: null, modalStatus: null } }))}
								name={t('cancel')}
								color='#fff'
								backgroundColor='#c1312f'
								height='48px'
							/>
							<Button onclick={giftApprove} name={t('confirm')} color='#fff' backgroundColor='#1392e5' height='48px' />
						</div>
					</div>
				</Modal>
			)}
		</div>
	)
}

export default OutgoingGiftDetail
